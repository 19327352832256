<template>
    <div class="card-footer-item" v-if="!existApplication">
        <!--APPLICATION REQUEST FORM-->
         <b-modal v-model="showApplicationRequestForm" has-modal-card>
             <div class="modal-card">
                 <div class="modal-card-head">
                     <p class="modal-card-title">
                         <!--TITLE-->
                         {{$t('Your application')}}
                     </p>
                     <span class="card-header-icon" @click="showApplicationRequestForm = !showApplicationRequestForm">
                         <i class="fa fa-times" ></i>
                     </span>
                 </div>
                 <div class="modal-card-body">
                     <!--FEE-->
                     <b-field :label="$t('Your payment offer')" :message="$t('Field is required')"
                              :type="{'is-danger': feeAmountRequired}"
                              v-if="vacancy.fee_amount_by_request">
                         <div class="field has-addons" >
                             <p class="control">
                           <span class="select" :class="{'is-danger': feeAmountRequired}">
                             <select v-model="feeCurrency" >
                               <option>UAH</option>
                               <option>USD</option>
                             </select>
                           </span>
                             </p>
                             <p class="control">
                                 <input type="number"
                                        class="input"
                                        :class="{'is-danger': feeAmountRequired}"
                                        :placeholder="$t('amount')"
                                        v-model="feeAmount"
                                        @input="feeAmountRequired = false">
                             </p>
                         </div>
                     </b-field>

                     <!--COMMENTS-->
                     <b-field :label="$t('Add a comment to your application')">
                         <b-input type="textarea" maxlength="255" v-model="comment"> </b-input>
                     </b-field>
                 </div>
                 <div class="modal-card-foot">

                     <b-button
                         :class="vacancy.available_for_application ? 'is-success' : 'is-warning'"
                         class="card-footer-item"
                         key="application-form-toggle"
                         :loading="formBusy"
                         @click.prevent="handleVacancyApplicationCreation">{{$t('Send application')}}
                     </b-button>
<!--                         <b-button v-if="showApplicationRequestForm" @click.prevent="cancelApplicationForm">{{$t('Cancel')}}</b-button>-->

                 </div>
             </div>


         </b-modal>

        <!--APPLICATION REQUEST BUTTONS-->
        <b-button key="application-form-toggle"
            :loading="formBusy"
            :class="vacancy.available_for_application && applicationsLeft ? 'is-success' : 'is-warning'"
            @click.prevent="handleVacancyApplicationCreation">
            {{$t('Send application')}} <b-tag class="ml-1" rounded v-if="!subscriptionDisabled && userHasFreePlan && !existApplication">{{ applicationsLeft }} / {{ applicationLimitThreshold }}</b-tag>
        </b-button>

    </div>
</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {mapActions, mapMutations, mapState} from "vuex";
    import {CREATE_ARTISTIC_APPLICATION} from "@/store/modules/artisticApplications/actions-types";
    import {DECREMENT_ARTISTIC_PROFILE_LIMIT} from "@/store/modules/artisticProfiles/mutation-types";
    import {PLAN_LIMIT_APPLY_FOR_VACANCY} from "@/properties/subscriptionPlanLimits";
    import {PLAN_FREE} from "@/properties/plans";
    import subscriptionDisabledMixin from "@/mixins/subscriptionDisabledMixin";

    export default {
        name: "RelevantVacancyApplicationRequest",
        props: {
            vacancy: {
                type: Object,
                default: function () {
                    return {};
                },
            }
        },
        data(){
            return {
                feeAmount: null,
                feeCurrency: 'UAH',
                comment: null,
                showApplicationRequestForm: false,
                feeAmountRequired: false,
            };
        },
        methods: {
            ...mapActions('artisticApplications', [
                CREATE_ARTISTIC_APPLICATION,
            ]),
            ...mapMutations('artisticProfiles', [
                DECREMENT_ARTISTIC_PROFILE_LIMIT,
            ]),

            handleVacancyApplicationCreation(){

                if (!this.vacancy.available_for_application || !this.applicationsLeft){
                  this.$buefy.dialog.alert({
                    title: this.$t('Subscription limit reached'),
                    message: this.$t('You have reached your vacancy application subscription limit'),
                    confirmText: this.$t('Go to the plans!'),
                    cancelText: this.$t('Cancel'),
                    canCancel: true,
                    onConfirm: this.visitSubscriptionPlansPage,
                  });

                  return;
                }

                if(!this.showApplicationRequestForm ){
                  this.showApplicationRequestForm = true;
                  return ;
                }

                if (this.dataForApplicationCreateReady){
                    this.createApplication();
                } else {
                    this.feeAmountRequired = true;
                }
            },
            visitSubscriptionPlansPage(){
              this.$router.push({name: 'subscription'});
            },
            cancelApplicationForm(){
                this.showApplicationRequestForm = false;
            },
            createApplication(){

                this.$_p_formDispatch(CREATE_ARTISTIC_APPLICATION,{
                    artisticProfileId: this.vacancy.target_artistic_profile_id,
                    applicationData: this.dataForApplicationCreate()
                }).then(() => {
                    this.resetData();
                    this.$buefy.toast.open(this.$t('Application sent'));
                    this[DECREMENT_ARTISTIC_PROFILE_LIMIT]({
                        profileId: this.vacancy.target_artistic_profile_id,
                        limitName: PLAN_LIMIT_APPLY_FOR_VACANCY
                    });
                });
                /*this[CREATE_ARTISTIC_APPLICATION]({
                    artisticProfileId: this.vacancy.target_artistic_profile_id,
                    applicationData: this.dataForApplicationCreate()
                })
                    .then(() => {
                        this.resetData();
                    });*/
            },
            dataForApplicationCreate(){
                return {
                    'comment': this.comment,
                    'fee_currency': this.feeCurrency,
                    'fee_amount': this.feeAmount,
                    'vacancy_id': this.vacancy.id,
                };
            },
            resetData(){
                this.showApplicationRequestForm = false;
                this.feeAmount = this.comment= null;
                this.feeCurrency = 'UAH';
            },
        },
        computed: {
            ...mapState({
                artisticApplications: state => state.artisticApplications.applications,
                artisticProfiles: state => state.artisticProfiles.profiles,
            }),
            existApplication(){
                return !! this.application;
            },
            dataForApplicationCreateReady(){
                return this.vacancy.fee_amount_by_request ? !!this.feeAmount : true;
            },
            application(){
                return this.artisticApplications ? this.artisticApplications.find(application => {
                    return (application.vacancy_id === this.vacancy.id) && (application.artistic_profile.id === parseInt(this.vacancy.target_artistic_profile_id)   );
                }) : null;
            },
            targetArtisticProfile(){
                return this.artisticProfiles ? this.artisticProfiles.find(profile => profile.id === this.vacancy.target_artistic_profile_id) : {};
            },
            userHasFreePlan(){
                return PLAN_FREE === this.$store.state.auth.user.subscription.plan.name;
            },
            applicationLimitThreshold(){
                return this.targetArtisticProfile ? this.targetArtisticProfile.limits.find(limit => limit.name === 'Apply for vacancy').value : null ;
            },
            applicationsLeft(){
                if (this.targetArtisticProfile){
                    let limit = this.targetArtisticProfile.limits.find(limit => limit.name === 'Apply for vacancy');
                    return limit.value - limit.pivot.value_used;
                } else {
                    return null;
                }
            }
        },
        mixins: [formHelperMixin, subscriptionDisabledMixin],
    };
</script>

<style scoped>

</style>