<!--        !!! COMPONENT TEMPORARY NOT IN USE !!! -->
<template>
    <div class="">
        <div class="section" v-if="vacancy">
            <div>
                <b-button icon-left="arrow-left" @click="closeDetails">{{$t('back')}}</b-button>
            </div>
            <!--TITLE-->
            <h5 class="title is-5" >{{vacancy.title}}</h5>
            <!--IS CLOSED-->
            <b-tag type="is-danger" v-if="vacancy.closed">{{$t('vacancy is closed')}}</b-tag>

            <!--FEE-->
            <div>
                {{$t('fee type')}}: {{ $t(vacancy.fee_type)}}
                <span v-if="'fixed' === vacancy.fee_type">, {{vacancy.fee_currency}}  {{ vacancy.fee_amount}}</span>
            </div>

            <!--CREATED AT-->
            <div>{{$t('vacancy created at')}}: {{vacancy.created_at | dateFromNow}}</div>

            <!--CITY-->
            <div v-if="vacancy.city_id">
                {{$t('city')}}: {{ vacancy.city.name}}
            </div>

            <!--DATE AND TIME-->
            <div v-if="vacancy.date || vacancy.time">
                <span v-if="vacancy.date">{{$t('date')}}:{{vacancy.date}},</span><!-- <span v-if="vacancy.time">time:{{vacancy.time}}</span>-->
            </div>

            <!--ABROAD FACTOR-->
            <div v-if="vacancy.abroad_contract">
                <span class="is-success">{{$t('is abroad contract')}}</span>
            </div>

            <!--LOCATION-->
            <div v-if="vacancy.location">
                {{ vacancy.location}}
            </div>

            <!--REQUIREMENTS-->
            <div v-if="vacancy.requirements">
                {{ vacancy.requirements }}
            </div>

            <!--EXAMPLES GALLERY-->
            <div v-if="vacancy && vacancy.images.length">
                <div class="columns">
                    <div class="columns" v-for="image in vacancy.images" :key="'image-' + image.id">
                        <figure class="image is-64x64" >
                            <img v-img="{group: 'vacancy-example-gallery-' + vacancy.id,src: getImgUrl(image)}"
                                 :src="getImgThumbUrl(image)" />
                        </figure>
                    </div>

                </div>
            </div>

            <!--INSTRUMENTS-->
            <b-taglist>
                <b-tag v-for="instrument in vacancy.instruments"
                       size="is-large"
                       :key="'vacancy-' + vacancy.id + '-instrument-' + instrument.id">
                    {{$t(instrument.name)}}
                </b-tag>
            </b-taglist>

            <!-- STYLISTIC OPTIONS-->
            <b-taglist>
                <b-tag v-for="stylisticOption in vacancy.stylistic_options"
                       :key="'vacancy-' + vacancy.id + '-option-' + stylisticOption.id"
                       size="is-large">
                    {{$t(stylisticOption.name)}}
                </b-tag>
            </b-taglist>

            <!--EXAMPLE LINKS-->
            <div>
                <ul>
                    <li v-for="link in vacancy.links" :key="'vacancy-' + vacancy.id + '-link-' + link.id">
                        <a :href="link.href" v-text="link.text" target="_blank"></a>
                    </li>
                </ul>
            </div>

            <!--APPLICATION STATUS-->
            <div v-if="existApplication" key="application-exists-tag">
                <strong>{{$t('application has been send')}}:<b-tag :type="'rejected' === application.status ? 'is-danger' : 'is-success'">{{$t(application.status)}}</b-tag></strong>
                <b-button
                    v-if="!vacancy.active || 'rejected' === application.status"
                    @click.prevent="archiveApplicationForArtisticProfile"
                    :loading="formBusy">{{$t('archive')}}</b-button>
            </div>
            <!--ORGANIZER CONTACTS, IF APPLICATIONS ACCEPTED-->
            <div v-if=" existApplication && 'accepted' === application.status" >
                {{$t('email')}}: {{ vacancy.user.email }}
                {{$t('name')}}: {{ vacancy.user.name}}
            </div>

            <relevant-vacancy-application-request :vacancy="vacancy" />


        </div>


    </div>

</template>

<script>
import imgUrlGenerator from "@/mixins/imgUrlGenerator";
import {mapActions, mapMutations, mapState} from "vuex";
import formHelperMixin from "@/mixins/formHelperMixin";
import RelevantVacancyApplicationRequest from "@/components/Artist/RelevantVacancies/ApplicationRequest";
import {
    REMOVE_RELEVANT_VACANCY_FROM_LIST,
    SET_VIEWABLE_RELEVANT_VACANCY
} from "@/store/modules/relevantVacancies/mutation-types";
import {
    ARCHIVE_ARTISTIC_APPLICATION,
    GET_ARTISTIC_APPLICATIONS
} from "@/store/modules/artisticApplications/actions-types";
import {GET_RELEVANT_VACANCY} from "@/store/modules/relevantVacancies/action-types";

export default {
    components: {RelevantVacancyApplicationRequest},
    data(){
        return {
        };
    },
    methods: {
        ...mapMutations('artisticApplications',[
            ARCHIVE_ARTISTIC_APPLICATION,
        ]),
        ...mapActions('artisticApplications', [
            GET_ARTISTIC_APPLICATIONS,
        ]),
        ...mapActions('relevantVacancies', [
            GET_RELEVANT_VACANCY
        ]),
        ...mapMutations('relevantVacancies', [
            SET_VIEWABLE_RELEVANT_VACANCY,
            REMOVE_RELEVANT_VACANCY_FROM_LIST,
        ]),
        closeDetails(){
            this[SET_VIEWABLE_RELEVANT_VACANCY](null);
            this.$router.push({
                name: 'artisticDashboard'
            });
        },
        archiveApplicationForArtisticProfile(){

            this[ARCHIVE_ARTISTIC_APPLICATION](this.application.id)
                .then(() => {
                    this[REMOVE_RELEVANT_VACANCY_FROM_LIST](this.index);
                });

        },
    },
    computed: {
        ...mapState({
            artisticApplications: state => state.artisticApplications.applications,
            vacancy: state => state.relevantVacancies.viewable,
        }),
        existApplication(){
            return !! this.application;
        },
        application(){
            return this.artisticApplications.find(application => {
                return (application.vacancy_id === this.vacancy.id) && (application.artistic_profile.id === this.vacancy.target_artistic_profile_id);
            });
        },
        openRelevantVacancyDetails(){
            return !! this.vacancy;
        },
    },
    async mounted() {
        if (this.$route.query.relevant_vacancy_id){
            await this[GET_ARTISTIC_APPLICATIONS]();
            await this[GET_RELEVANT_VACANCY]({
                vacancyId : this.$route.query.relevant_vacancy_id,
                targetArtisticProfileId: this.$route.query.target_artistic_profile_id,
            });
        }
    },
    watch: {
        '$route.query.relevant_vacancy_id': async function() {
            if (this.$route.query.relevant_vacancy_id){
                if (!this.artisticApplications){
                    await this[GET_ARTISTIC_APPLICATIONS]();
                }

                if (this.$route.params.relevant_vacancy){
                    return this[SET_VIEWABLE_RELEVANT_VACANCY](this.$route.params.relevant_vacancy);
                }

                await this[GET_RELEVANT_VACANCY]({
                    vacancyId : this.$route.query.relevant_vacancy_id,
                    targetArtisticProfileId: this.$route.query.target_artistic_profile_id,
                });

            }
        }
    },
    mixins: [imgUrlGenerator, formHelperMixin],
    name: "RelevantVacancyView",
};
</script>

<style scoped>

</style>